import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import { Title, Align, Padding, Text } from "../Components";

const Key = ({ bind, label }) => (
  <Padding left={16} right={16} bottom={16}>
    <Align align="center" justify="center" wrap="nowrap">
      <Padding
        top={6}
        left={12}
        right={12}
        bottom={6}
        style={{
          borderRadius: 8,
          border: "1px solid rgba(27, 44, 85, 0.2)",
        }}
      >
        <Text weight="400" size={14} line={14}>
          {bind}
        </Text>
      </Padding>
      <Padding left={12}>
        <Text weight="500" size={18}>
          {label}
        </Text>
      </Padding>
    </Align>
  </Padding>
);

class Component extends React.Component {
  render() {
    return (
      <ScreenClassRender
        render={(screen) =>
          ["xs", "sm", "md"].includes(screen) ? null : (
            <Container style={{ paddingBottom: 64, paddingTop: 64 }}>
              <Row>
                <Col xs={12}>
                  <Title align="center" bottom={32}>
                    Kısayol Tuşları
                  </Title>
                  <Align wrap="wrap" align="center" justify="center">
                    <Key bind="Enter" label="Başlat / Durdur" />
                    <Key bind="S" label="Sıfırla" />
                    <Key bind="K" label="Kayıt" />
                    <Key bind="T" label="Kayıtları Temizle" />
                    <Key bind="M" label="Milisaniyeleri Göster" />
                  </Align>
                </Col>
              </Row>
            </Container>
          )
        }
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
