import React from "react";

const Sources = {
  light: "/i/logo-light.svg",
  dark: "/i/logo-dark.svg",
};

const Component = ({ theme = "dark" }) => (
  <a
    href="/"
    style={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <img
      src={Sources[theme]}
      style={{ height: 24, width: "auto" }}
      alt="Ilao - Istanbul Based Creative Team. We develop web &amp;amp; mobile applications for startups, enterprises and companies."
    />
  </a>
);

export default Component;
