import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { HomeScreen, BlogScreen, PostScreen, StaticScreen } from "../Pages";

class Component extends React.PureComponent {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/blog" component={BlogScreen} />
          <Route exact path="/blog/:blog" component={PostScreen} />
          <Route exact path="/static/:static" component={StaticScreen} />
        </Switch>
      </Router>
    );
  }
}

export default Component;
