import React from "react";
import { connect } from "react-redux";
import {
  NavbarContainer,
  FooterContainer,
  FeaturesContainer,
  TutorialContainer,
  CronometerContainer,
  ModalsContainer
} from "../Containers";

import { Consent } from "../Components";

class Component extends React.PureComponent {
  render() {
    return (
      <div>
        <NavbarContainer />
        <CronometerContainer />
        <TutorialContainer />
        <FeaturesContainer />
        <FooterContainer />
        <ModalsContainer />
        <Consent />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
