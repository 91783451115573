import React from "react";

import Text from "../Atoms/Text";

const Component = ({ children, bottom, style, align }) => (
  <Text
    size={24}
    line={34}
    weight="600"
    block
    bottom={bottom}
    style={style}
    align={align}
  >
    {children}
  </Text>
);

export default Component;
