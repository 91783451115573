import { createStore } from "redux";

const STATE = {
  reload: true,
  blogs: [],
  blog: {},
  static: {},
  app: {
    isStarted: false,
    history: [],
    time: 0,
    note: "",
    settings: {
      showMiliseconds: false,
      playSoundEveryTick: false,
      tickDuration: 0,
    },
    modals: {
      settings: false,
      notes: false,
      sound: false
    }
  },
};

const reducer = (state = STATE, action) => {
  var holder = { ...state };
  switch (action.type) {
    case "CORE":
      holder[action.key] = action.value;
      return holder;
    default:
      return holder;
  }
};

const store = createStore(reducer);

export default store;
