import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import { Subtitle, Paragraph } from "../Components";

class Component extends React.Component {
  render() {
    return (
      <ScreenClassRender
        render={(screen) =>
          ["xs", "sm", "md"].includes(screen) ? null : (
            <Container>
              <Row>
                <Col md={6} lg={3}>
                  <Subtitle>Hakkında</Subtitle>
                  <Paragraph bottom={48}>
                    Kronometer.co süre ölçmek ve ara süreleri tutmak için
                    kullanılan online kronometredir. Süre ölç, ara süreleri tut.
                  </Paragraph>
                </Col>
                <Col md={6} lg={3}>
                  <Subtitle>Tur Kayıtları</Subtitle>
                  <Paragraph bottom={48}>
                    Tur kayıtları kısmınızdan tüm kayıtları temizleyebilir,
                    Excel/CSV formatlarında dışarı aktarabilir ve dilediğiniz
                    kaydı silebilirsiniz.
                  </Paragraph>
                </Col>
                <Col md={6} lg={3}>
                  <Subtitle>Sayaç</Subtitle>
                  <Paragraph bottom={48}>
                    Sayaç kısmından anlık süreyi görebilir, sayacı başlatıp
                    durdurabilir, kayıt alabilir ve anlık turunuzu
                    kaydedebilirsiniz.
                  </Paragraph>
                </Col>
                <Col md={6} lg={3}>
                  <Subtitle>Menü</Subtitle>
                  <Paragraph bottom={48}>
                    Menü’den zil sesi ayarlarınızı yapabilir, not alabilir ve
                    kısayol milisaniyeleri göstermek gibi ayarlarınızı
                    yapabilirsiniz.
                  </Paragraph>
                </Col>
              </Row>
            </Container>
          )
        }
      />
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
