import React from "react";

import Text from "../Atoms/Text";

const Component = ({ children, bottom, align }) => (
  <Text size={48} line={48} weight="600" block bottom={bottom} align={align}>
    {children}
  </Text>
);

export default Component;
