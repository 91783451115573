import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import {
  Text,
  Align,
  Link,
  Button,
  Subtitle,
  Padding,
  Icon,
} from "../Components";
import { Colors, Settings } from "../Helpers";
import Moment from "moment";
import { Helmet } from "react-helmet";
import Export from "export-from-json";

const HistoryContainer = ({ children }) => (
  <div
    className="--scroll"
    style={{
      width: "100%",
      height: 250,
      borderRadius: 8,
      border: "1px solid rgba(27, 44, 85, 0.2)",
      overflowY: "scroll",
      overflowX: "visible",
    }}
  >
    {children}
  </div>
);

const History = ({ title, subtitle, label, color = Colors.russian, click }) => (
  <Padding
    left={8}
    right={8}
    display="block"
    style={{
      position: "relative",
      overflow: "visible",
      borderBottom: "1px solid rgba(27, 44, 85, 0.2)",
    }}
  >
    <Align align="center" justify="space-between">
      <Align align="center">
        <div
          style={{
            width: 12,
            height: 12,
            border: "1px solid rgba(27, 44, 85, 0.2)",
            borderRadius: 6,
            marginRight: 6,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={click}
        >
          <Icon src="x" size={8} />
        </div>
        <Text weight="600" size={14} line={32}>
          {title}
        </Text>
      </Align>
      <Align align="center">
        <Text weight="600" size={14} line={32} color={color}>
          {subtitle}
        </Text>
        <Text weight="600" size={12} line={32} opacity={0.75} left={8}>
          {label}
        </Text>
      </Align>
    </Align>
  </Padding>
);

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      point: undefined,
      lastSecondPlayedSound: 0,
    };
  }

  componentDidMount() {
    var audio = new Audio();
    audio.play();
    document.addEventListener(
      "keydown",
      (event) => {
        const Keys = {
          Enter: 13,
          S: 83,
          K: 75,
          T: 84,
          M: 77,
        };

        const key = event.keyCode;
        var app = this.props.redux.app;

        if (key === Keys.Enter) {
          app.isStarted = !app.isStarted;
          this.props.core("app", app);
        }

        if (key === Keys.S) {
          this.reset();
        }

        if (key === Keys.K) {
          this.saveTime();
        }

        if (key === Keys.T) {
          this.clearHistory();
        }

        if (key === Keys.M) {
          app.settings.showMiliseconds = !app.settings.showMiliseconds;
          this.props.core("app", app);
        }
      },
      false
    );
    this.interval = setInterval(() => {
      this.props.core("reload", false);
      if (this.props.redux.app.isStarted) {
        if (this.state.point) {
          const app = this.props.redux.app;
          const point = new Moment().valueOf();
          const difference = point - this.state.point;

          app.time = app.time + difference;

          this.props.core("app", app);
          this.setState({ point: point });
        } else {
          this.setState({ point: new Moment().valueOf() });
        }
      } else {
        this.setState({ point: new Moment().valueOf() });
        this.props.core("reload", true);
      }
    }, 1);
    this.sound = setInterval(() => {
      if (this.props.redux.app.settings.playSoundEveryTick) {
        const time = this.props.redux.app.time;
        var seconds = parseInt(time / 1000);

        if (
          seconds % this.props.redux.app.settings.tickDuration === 0 &&
          seconds !== this.state.lastSecondPlayedSound
        ) {
          audio.src = "https://www.soundjay.com/button/beep-07.mp3";
          audio.play();
          this.setState({ lastSecondPlayedSound: seconds });
        }
      }
    }, 1);
  }

  exportHistoryToExcel = () => {
    var histories = this.props.redux.app.history;
    var data = [];

    histories.map((history, h) => {
      var time = history.time;
      var minutes = 0;
      if (time > 60000) {
        minutes = parseInt(time / 60000);
      }
      var seconds = parseInt((time - minutes * 60000) / 1000);
      var miliseconds = String(time).substring(String(time).length - 3);

      var placeholder = {
        Tur: h + 1 + ". Tur",
        Süre:
          this.renderMinutesAndSeconds(minutes, seconds) + "." + miliseconds,
      };

      data.push(placeholder);
      return true;
    });

    const fileName = "history";
    const exportType = "csv";

    Export({ data, fileName, exportType });
  };

  renderMinutesAndSeconds = (minutes, seconds) => {
    return (
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds)
    );
  };

  renderHistory = (history, h) => {
    var title = h + 1 + ". Tur";
    var time = history.time;
    var color = Colors.russian;
    var minutes = 0;
    if (time > 60000) {
      minutes = parseInt(time / 60000);
    }
    var seconds = parseInt((time - minutes * 60000) / 1000);
    var subtitle = this.renderMinutesAndSeconds(minutes, seconds);

    var label = "";

    if (h > 0) {
      var previousHistory = this.props.redux.app.history[h - 1];
      var timeBetweenHistories = history.time - previousHistory.time;

      if (timeBetweenHistories > previousHistory.time) {
        color = "#EB5757";
      }
      if (timeBetweenHistories <= previousHistory.time) {
        color = "#219653";
      }

      if (h > 1) {
        var previousSecondHistory = this.props.redux.app.history[h - 2];
        var timeBetweenSecondHistories =
          previousHistory.time - previousSecondHistory.time;

        if (timeBetweenHistories > timeBetweenSecondHistories) {
          color = "#EB5757";
        }
        if (timeBetweenHistories <= timeBetweenSecondHistories) {
          color = "#219653";
        }
      }

      var labelMinutes = 0;
      if (timeBetweenHistories > 60000) {
        labelMinutes = parseInt(time / 60000);
      }
      var labelSeconds = parseInt(
        (timeBetweenHistories - labelMinutes * 60000) / 1000
      );
      var labelMiliseconds = String(timeBetweenHistories).substring(
        String(timeBetweenHistories).length - 3
      );

      label =
        "(Tur: " +
        this.renderMinutesAndSeconds(labelMinutes, labelSeconds) +
        ")";

      if (this.props.redux.app.settings.showMiliseconds) {
        label =
          "(Tur: " +
          this.renderMinutesAndSeconds(labelMinutes, labelSeconds) +
          "." +
          labelMiliseconds +
          ")";
      }
    }

    return (
      <History
        title={title}
        subtitle={subtitle}
        label={label}
        color={color}
        click={() => {
          var history = this.props.redux.app.history;
          history.splice(h, 1);
          var app = this.props.redux.app;
          app.history = history;
          this.props.core("app", app);
        }}
      />
    );
  };

  saveTime = () => {
    var app = this.props.redux.app;

    app.history.push({ time: this.props.redux.app.time });

    this.props.core("app", app);
  };

  clearHistory = () => {
    var app = this.props.redux.app;

    app.history = [];

    this.props.core("app", app);
  };

  reset = () => {
    var app = this.props.redux.app;

    app.history = [];
    app.time = 0;

    this.props.core("app", app);
  };

  showSettingModal = () => {
    var app = this.props.redux.app;
    app.modals.settings = true;
    this.props.core("app", app);
  };
  showNotesModal = () => {
    var app = this.props.redux.app;
    app.modals.notes = true;
    this.props.core("app", app);
  };
  showSoundModal = () => {
    var app = this.props.redux.app;
    app.modals.sound = true;
    this.props.core("app", app);
  };

  render() {
    const time = this.props.redux.app.time;
    var minutes = 0;

    if (time > 60000) {
      minutes = parseInt(time / 60000);
    }

    var seconds = parseInt((time - minutes * 60000) / 1000);

    return (
      <>
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>
            {time > 0
              ? this.renderMinutesAndSeconds(minutes, seconds) +
                " | Kronometre.co"
              : Settings.website.title}
          </title>
        </Helmet>
        <ScreenClassRender
          render={(screen) =>
            ["xs", "sm", "md"].includes(screen) ? (
              <div
                style={{
                  height: window.innerHeight - 72,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Padding top={24} bottom={24} display="block">
                  <Align bottom={0} align="center" direction="column">
                    <Text size={72} line={72} weight="600" align="center">
                      {(minutes < 10 ? "0" + minutes : minutes) +
                        ":" +
                        (seconds < 10 ? "0" + seconds : seconds)}
                      {this.props.redux.app.settings.showMiliseconds ? (
                        <Text
                          opacity={0.5}
                          size={32}
                          weight="600"
                          style={{ display: "block" }}
                          align="right"
                          line={32}
                        >
                          .
                          {time > 999
                            ? String(time).substring(String(time).length - 3)
                            : time}
                        </Text>
                      ) : null}
                    </Text>
                  </Align>
                </Padding>
                <Align align="center" justify="center" bottom={24}>
                  <Button
                    width={4}
                    align="center"
                    type="ghost"
                    click={this.reset}
                    left={4}
                    right={4}
                    square
                  >
                    <Icon src="reset" />
                  </Button>
                  <Button
                    width={60}
                    align="center"
                    type="active"
                    left={4}
                    right={4}
                    click={() => {
                      var app = this.props.redux.app;
                      app.isStarted = !app.isStarted;
                      this.props.core("app", app);
                    }}
                  >
                    {this.props.redux.app.isStarted ? "Durdur" : "Başlat"}
                  </Button>
                  <Button
                    width={4}
                    align="center"
                    type="ghost"
                    click={this.saveTime}
                    left={4}
                    right={4}
                    square
                  >
                    <Icon src="save" />
                  </Button>
                </Align>
                <div
                  className="--scroll"
                  style={{
                    flex: 1,
                    backgroundColor: "#F7F7F7",
                    marginTop: 0,
                    overflowY: "scroll",
                    position: "relative",
                  }}
                >
                  {this.props.redux.app.history.map((history, h) =>
                    this.renderHistory(history, h)
                  )}
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "50%",
                      left: 0,
                      bottom: 0,
                      backgroundImage:
                        "linear-gradient(0deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
                    }}
                  />
                  <Padding
                    top={24}
                    bottom={24}
                    left={24}
                    right={24}
                    display="block"
                    style={{
                      position: "absolute",
                      width: "100%",
                      left: 0,
                      bottom: 0,
                      boxSizing: "border-box",
                    }}
                  >
                    <Align align="center" justify="space-between">
                      <Button
                        width={4}
                        align="center"
                        type="ghost"
                        left={4}
                        right={4}
                        click={this.showSoundModal}
                        square
                      >
                        <Icon src="notification" />
                      </Button>
                      <Button
                        width={4}
                        align="center"
                        type="ghost"
                        left={4}
                        right={4}
                        click={this.showSettingModal}
                        square
                      >
                        <Icon src="settings" />
                      </Button>
                      <Button
                        width={4}
                        align="center"
                        type="ghost"
                        left={4}
                        right={4}
                        click={this.clearHistory}
                        square
                      >
                        <Icon src="clear" />
                      </Button>
                      <Button
                        width={4}
                        align="center"
                        type="ghost"
                        left={4}
                        right={4}
                        click={this.showNotesModal}
                        square
                      >
                        <Icon src="notes" />
                      </Button>
                    </Align>
                  </Padding>
                </div>
              </div>
            ) : (
              <Container>
                <Row justify="space-between">
                  <Col md={3}>
                    <Subtitle bottom={12}>Kayıtlarım</Subtitle>
                    <HistoryContainer>
                      {this.props.redux.app.history.map((history, h) =>
                        this.renderHistory(history, h)
                      )}
                    </HistoryContainer>
                    <Button
                      click={this.exportHistoryToExcel}
                      bottom={0}
                      type="small"
                      style={{
                        border: "1px solid rgba(0,0,0,0.09)",
                        background: "transparent",
                        color: Colors.russian,
                        opacity: 0.85,
                        width: "100%",
                        boxSizing: "border-box",
                        marginBottom: 12,
                        fontSize: 12,
                        height: 24,
                        lineHeight: "24px",
                        display: "block",
                        display: "inline-flex",
                        alignItems: "center",
                        justify: "center",
                        marginTop: 12,
                      }}
                    >
                      Kayıtları Dışarı Aktar
                    </Button>
                    <Align align="center" justify="space-between" top={0}>
                      <Link click={this.saveTime}>Kaydet</Link>
                      <Link color="#EB5757" click={this.clearHistory}>
                        Temizle
                      </Link>
                    </Align>
                  </Col>
                  <Col md={4}>
                    <Align bottom={32} align="flex-end" justify="center">
                      <Text size={120} line={120} weight="600">
                        {(minutes < 10 ? "0" + minutes : minutes) +
                          ":" +
                          (seconds < 10 ? "0" + seconds : seconds)}
                      </Text>
                      {this.props.redux.app.settings.showMiliseconds ? (
                        <Text
                          opacity={0.5}
                          size={32}
                          weight="600"
                          style={{ width: 0 }}
                        >
                          .
                          {time > 999
                            ? String(time).substring(String(time).length - 3)
                            : time}
                        </Text>
                      ) : null}
                    </Align>
                    <Align align="center" justify="space-between">
                      <Button
                        width={60}
                        align="center"
                        type="ghost"
                        click={this.reset}
                        left={4}
                        right={4}
                      >
                        Sıfırla
                      </Button>
                      <Button
                        width={60}
                        align="center"
                        type="ghost"
                        click={this.saveTime}
                        left={4}
                        right={4}
                      >
                        Kaydet
                      </Button>
                      <Button
                        width={60}
                        align="center"
                        type="active"
                        left={4}
                        right={4}
                        click={() => {
                          var app = this.props.redux.app;
                          app.isStarted = !app.isStarted;
                          this.props.core("app", app);
                        }}
                      >
                        {this.props.redux.app.isStarted ? "Durdur" : "Başlat"}
                      </Button>
                    </Align>
                  </Col>
                  <Col md={3}>
                    <Align direction="column" align="flex-end">
                      <Button
                        width={80}
                        bottom={24}
                        align="center"
                        type="ghost"
                        click={this.showSoundModal}
                      >
                        Zil Sesi
                      </Button>
                      <Button
                        width={80}
                        bottom={24}
                        align="center"
                        type="ghost"
                        click={this.showSettingModal}
                      >
                        Ayarlar
                      </Button>
                      <Button
                        width={80}
                        align="center"
                        type="ghost"
                        click={this.showNotesModal}
                      >
                        Not Al
                      </Button>
                    </Align>
                  </Col>
                </Row>
              </Container>
            )
          }
        />
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
