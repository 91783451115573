import React from "react";
import { Navigator, Store, API } from "./Modules";
import { Settings } from "./Helpers";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";

class App extends React.Component {
  async componentDidMount() {
    await API.fire();
  }

  render() {
    return (
      <Provider store={Store}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{Settings.website.title}</title>
          <meta name="description" content={Settings.website.description} />
        </Helmet>
        <Navigator />
      </Provider>
    );
  }
}

export default App;
