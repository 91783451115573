import React from "react";
import { Colors } from "../../Helpers";
import Text from "../Atoms/Text";

const Component = ({
  children,
  bottom = 24,
  style,
  color = "#175CFF",
  click,
}) => (
  <a onClick={click}>
    <Text
      size={18}
      line={18}
      weight="600"
      color={color}
      bottom={bottom}
      style={style}
    >
      {children}
    </Text>
  </a>
);

export default Component;
