import React from "react";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import { Text, Align } from "../Components";
import { Colors } from "../Helpers";

const SmallInput = ({ value, change, blur, min, max }) => (
  <input
    min={min}
    max={max}
    value={value}
    onChange={change}
    onBlur={blur}
    type="number"
    style={{
      color: Colors.space,
      fontSize: 16,
      fontFamily: "Gilroy",
      backgroundColor: "transparent",
      border: "1px solid rgba(27,44,85, 0.1)",
      padding: 4,
      width: 40,
      display: "inline-block",
      textAlign: "right",
      borderRadius: 8,
      outline: "none",
    }}
  />
);

const TextArea = ({ value, change }) => (
  <div
    style={{
      boxSizing: "border-box",
      backgroundColor: "white",
      padding: 24,
      height: 175,
    }}
  >
    <textarea
      onChange={change}
      placeholder="Düzenlemek için tıklayınız..."
      style={{
        border: "none",
        backgroundColor: "transparent",
        color: Colors.space,
        width: "100%",
        height: "100%",
        outline: "none",
        fontSize: 16,
        fontFamily: "Gilroy",
      }}
    >
      {value}
    </textarea>
  </div>
);

const Modal = ({ close, children, show, title }) =>
  show ? (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(27,44,85, 0.3)",
        display: "flex",
        flexDirection: "column",
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{ flex: 1, backgroundColor: "transparent" }}
        onClick={close}
      ></div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          maxWidth: 500,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F7F7F7",
            height: 60,
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Text weight="600">{title}</Text>
          <Text weight="600" color="#175CFF" click={close}>
            İptal
          </Text>
        </div>
        {children}
      </div>
    </div>
  ) : null;

const ModalRow = ({ children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#FFFFFF",
      height: 60,
      paddingLeft: 24,
      paddingRight: 24,
    }}
  >
    {children}
  </div>
);

const Switch = ({ active, click }) => (
  <div
    onClick={click}
    style={{
      width: 56,
      height: 32,
      boxSizing: "border-box",
      padding: 4,
      border: "1px solid rgba(27,44,85, 0.1)",
      borderRadius: 999,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: active ? "flex-end" : "flex-start",
    }}
  >
    <div
      style={{
        width: 24,
        height: 24,
        borderRadius: 999,
        backgroundColor: active ? "#175CFF" : "rgba(27,44,85, 0.1)",
      }}
    />
  </div>
);

class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: 0,
      seconds: 10,
    };
  }

  componentDidMount() {
    const time = this.props.redux.app.settings.tickDuration;
    var minutes = 0;

    if (time > 60) {
      minutes = parseInt(time / 60);
    }

    var seconds = parseInt((time - minutes * 60) / 1);

    this.setState({ minutes, seconds });
  }

  refactorNumbers = () => {
    var minutes = parseInt(this.state.minutes);

    minutes = minutes > 120 ? 120 : minutes;
    minutes = minutes < 0 ? 0 : minutes;

    var seconds = parseInt(this.state.seconds);

    seconds = seconds > 59 ? 59 : seconds;
    seconds = seconds < 0 ? 0 : seconds;

    const time = minutes * 60 + seconds;

    const app = this.props.redux.app;
    app.settings.tickDuration = time;
    this.props.core("app", app);
    this.setState({ minutes, seconds });
  };

  render() {
    return (
      <>
        <Modal
          title="Ayarlar"
          show={this.props.redux.app.modals.settings}
          close={() => {
            var app = this.props.redux.app;
            app.modals.settings = false;
            this.props.core("app", app);
          }}
        >
          <ModalRow>
            <Text weight="600">Milisaniyeleri Göster</Text>
            <Switch
              active={this.props.redux.app.settings.showMiliseconds}
              click={() => {
                var app = this.props.redux.app;
                app.settings.showMiliseconds = !app.settings.showMiliseconds;
                this.props.core("app", app);
              }}
            />
          </ModalRow>
        </Modal>
        <Modal
          title="Not Al"
          show={this.props.redux.app.modals.notes}
          close={() => {
            var app = this.props.redux.app;
            app.modals.notes = false;
            this.props.core("app", app);
          }}
        >
          <TextArea
            value={this.props.redux.app.note}
            change={(event) => {
              var app = this.props.redux.app;
              app.note = event.target.value;
              Cookie.set("note", event.target.value);
              this.props.core("app", app);
            }}
          />
        </Modal>
        <Modal
          title="Zil Sesi Ayarları"
          show={this.props.redux.app.modals.sound}
          close={() => {
            var app = this.props.redux.app;
            app.modals.sound = false;
            this.props.core("app", app);
          }}
        >
          <ModalRow>
            <Text weight="600">Zil Sesi Çal</Text>
            <Switch
              active={this.props.redux.app.settings.playSoundEveryTick}
              click={() => {
                var app = this.props.redux.app;
                app.settings.playSoundEveryTick = !app.settings.playSoundEveryTick;
                this.props.core("app", app);
              }}
            />
          </ModalRow>
          <ModalRow>
            <Text weight="600">Tekrarlanma Süresi</Text>
            <Align align="center">
              <SmallInput
                min={0}
                max={120}
                value={this.state.minutes}
                change={(event) =>
                  this.setState({
                    minutes:
                      event.target.value.length === 0
                        ? 0
                        : parseInt(event.target.value),
                  })
                }
                blur={this.refactorNumbers}
              />
              <Text weight="600" left={4} right={12}>
                dk
              </Text>
              <SmallInput
                min={0}
                max={59}
                value={this.state.seconds}
                change={(event) =>
                  this.setState({
                    seconds:
                      event.target.value.length === 0
                        ? 0
                        : parseInt(event.target.value),
                  })
                }
                blur={this.refactorNumbers}
              />
              <Text weight="600" left={4}>
                sn
              </Text>
            </Align>
          </ModalRow>
        </Modal>
      </>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
