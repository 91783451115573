import Airtable from "airtable";
import Cookie from "js-cookie";
import Store from "./Store";
import { Settings } from "../Helpers";

export default class API {
  static fire() {
    var app = Store.getState().app;
    app.note = Cookie.get("note");
    Store.dispatch({ type: "CORE", key: "app", value: app });
  }

  static blogs() {
    const Base = new Airtable({ apiKey: Settings.airtable.blogAPI }).base(
      Settings.airtable.blogBase
    );

    Base("Blogs")
      .select({
        filterByFormula: "{Project} = '" + Settings.airtable.projectName + "'",
      })
      .firstPage(function (error, records) {
        var blogs = [];

        records.map((record) => {
          var blog = {};
          blog.id = record.id;
          blog.title = record.get("Title");
          blog.content = record.get("Content");
          blog.src = record.get("Attachment")[0].url;
          blogs.push(blog);
          return true;
        });

        Store.dispatch({ type: "CORE", key: "blogs", value: blogs });
      });

    return true;
  }

  static post(id) {
    const Base = new Airtable({ apiKey: Settings.airtable.blogAPI }).base(
      Settings.airtable.blogBase
    );

    Base("Blogs").find(id, function (error, record) {
      var blog = {};
      blog.id = record.id;
      blog.title = record.get("Title");
      blog.content = record.get("Content");
      blog.src = record.get("Attachment")[0].url;

      Store.dispatch({ type: "CORE", key: "blog", value: blog });
    });

    return true;
  }

  static static(key) {
    const Base = new Airtable({ apiKey: Settings.airtable.staticAPI }).base(
      Settings.airtable.staticBase
    );

    Base("Static")
      .select({
        filterByFormula: "{Key} = '" + key + "'",
      })
      .firstPage(function (err, records) {
        records.forEach(function (record) {
          Store.dispatch({
            type: "CORE",
            key: "static",
            value: {
              title: record.get("Title"),
              content: record.get("Content"),
            },
          });
        });
      });

    return true;
  }
}
