import React from "react";

import { Colors } from "../../Helpers";

const Styles = {
  default: {
    display: "inline-block",
    height: 52,
    lineHeight: "52px",
    paddingLeft: 53,
    paddingRight: 52,
    borderStyle: "solid",
    borderRadius: 999,
    borderWidth: 2,
    borderColor: Colors.platinum,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.ultramarine,
    textDecoration: "none",
    cursor: "pointer",
    "white-space": "nowrap",
  },
  ghost: {
    display: "inline-block",
    height: 52,
    lineHeight: "52px",
    paddingLeft: 24,
    paddingRight: 24,
    borderStyle: "solid",
    borderRadius: 999,
    borderWidth: 2,
    borderColor: Colors.platinum,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.russian,
    textDecoration: "none",
    cursor: "pointer",
  },
  active: {
    display: "inline-block",
    height: 52,
    lineHeight: "52px",
    paddingLeft: 24,
    paddingRight: 24,
    borderStyle: "solid",
    borderRadius: 999,
    borderWidth: 2,
    borderColor: Colors.ultramarine,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.white,
    textDecoration: "none",
    backgroundColor: Colors.ultramarine,
    cursor: "pointer",
  },
  small: {
    display: "inline-block",
    height: 46,
    lineHeight: "46px",
    paddingLeft: 16,
    paddingRight: 16,
    borderStyle: "solid",
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.russian,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.white,
    textDecoration: "none",
    backgroundColor: Colors.russian,
    cursor: "pointer",
  },
  large: {
    display: "inline-block",
    lineHeight: "18px",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 11,
    paddingBottom: 11,
    borderRadius: 5,
    borderColor: Colors.platinum,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.white,
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: Colors.russian,
  },
  tiny: {
    display: "inline-block",
    height: 35,
    width: 80,
    lineHeight: "35px",
    paddingLeft: 18,
    paddingRight: 18,
    borderStyle: "solid",
    borderRadius: 8,
    borderWidth: 2,
    borderColor: Colors.platinum,
    fontWeight: "600",
    fontSize: 16,
    color: Colors.ultramarine,
    textDecoration: "none",
    cursor: "pointer",
    "white-space": "nowrap",
    marginLeft: 16,
    textAlign: "center",
  },
};

const Component = ({
  children,
  type = "default",
  background,
  color,
  click,
  style,
  href,
  bottom = 0,
  width,
  align,
  left = 0,
  right = 0,
  square
}) =>
  type === "large" ? (
    <a
      href={href}
      onClick={click}
      style={{
        ...Styles[type],
        backgroundColor: background,
        color: color,
        marginBottom: bottom,
        whiteSpace: "no-wrap",
        textAlign: align,
        marginLeft: left,
        marginRight: right,
        width: square ? 52 : width,
        height: square ? 52 : undefined,
        display: square ? "inline-flex" : undefined,
        alignItems: "center",
        justifyContent: "center",
        boxSizing: square ? "border-box" : undefined,
        cursor: "pointer",
        ...style,
      }}
    >
      {children}
    </a>
  ) : (
    <a
      href={href}
      onClick={click}
      style={{
        ...Styles[type],
        marginBottom: bottom,
        whiteSpace: "no-wrap",
        textAlign: align,
        marginLeft: left,
        marginRight: right,
        width: square ? 52 : width,
        height: square ? 52 : undefined,
        display: square ? "inline-flex" : undefined,
        alignItems: "center",
        justifyContent: "center",
        boxSizing: square ? "border-box" : undefined,
        cursor: "pointer",
        ...style,
      }}
    >
      {children}
    </a>
  );

export default Component;
