import React from "react";
import { Colors } from "../../Helpers";
import Text from "../Atoms/Text";

const Component = ({
  children,
  bottom = 24,
  align,
  color = Colors.independence,
  opacity,
  size = 16,
}) => (
  <Text
    size={size}
    line={24}
    weight="400"
    color={color}
    align={align}
    block
    bottom={bottom}
    opacity={opacity}
  >
    {children}
  </Text>
);

export default Component;
