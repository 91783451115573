import React from "react";

import { Colors } from "../../Helpers";

const Component = ({
  children,
  size,
  weight,
  color = Colors.space,
  line,
  block,
  bottom = 0,
  opacity = 1,
  align = "left",
  style,
  click,
  left,
  right
}) => (
  <span
    style={{
      fontSize: size,
      fontWeight: weight,
      color: color,
      lineHeight: line ? line + "px" : undefined,
      display: block ? "block" : "inline-block",
      marginBottom: bottom,
      opacity: opacity,
      textAlign: align,
      cursor: click ? "pointer" : undefined,
      marginLeft: left,
      marginRight: right,
      ...style,
    }}
    onClick={click}
  >
    {children}
  </span>
);

export default Component;
