import React from "react";
import { ScreenClassRender } from "react-grid-system";

import { Padding, Button, Align, Logo, Text } from "../Components";
import { Colors, Settings } from "../Helpers";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";

class Component extends React.PureComponent {
  render() {
    return (
      <ScreenClassRender
        render={(screen) =>
          ["xs", "sm"].includes(screen) ? (
            <Container>
              <Padding top={16} bottom={16} display="block">
                <Align align="center" justify="space-between">
                  <Align align="center">
                    <Logo />
                  </Align>
                  {this.props["no-blog"] ? null : (
                    <Link to="/blog">
                      <Text
                        weight="600"
                        size={20}
                        line={32}
                        bottom={8}
                        color={Colors.russian}
                      >
                        Blog
                      </Text>
                    </Link>
                  )}
                </Align>
              </Padding>
            </Container>
          ) : (
            <Container>
              <Padding top={48} bottom={48 + 60} display="block">
                <Align align="center" justify="space-between">
                  <Align align="center">
                    <Logo />
                    <div
                      style={{
                        borderLeft: "1px dashed rgba(0,0,0,0.17)",
                        marginLeft: 18,
                        paddingLeft: 18,
                      }}
                    >
                      <Text>{Settings.website.slogan}</Text>
                    </div>
                  </Align>
                  {this.props["no-blog"] ? null : (
                    <Button type="ghost" href="/blog">
                      Blog
                    </Button>
                  )}
                </Align>
              </Padding>
            </Container>
          )
        }
      ></ScreenClassRender>
    );
  }
}

export default Component;
