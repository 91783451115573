import React from "react";
import { Colors, Settings } from "../Helpers";
import { Logo, Padding, Text } from "../Components";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

class Component extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          marginTop: 0,
          paddingTop: 48,
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
          borderColor: Colors.platinum,
        }}
      >
        <Container>
          <Row>
            <Col xs={12} sm={12} md={4} style={{ marginBottom: 24 }}>
              <Logo />
              <Padding top={24} display="block">
                <Text size={20} line={32} weight="600">
                  {Settings.website.email}
                </Text>
              </Padding>
              <Padding top={24} display="block">
                <Text size={20} line={32} opacity={0.5}>
                  {Settings.website.copyright}
                </Text>
              </Padding>
            </Col>
            <Col xs={12} sm={12} md={4} style={{ marginBottom: 24 }}>
              <Text size={18} line={32} bottom={32} weight="600">
                MENÜ
              </Text>
              <Row>
                <Col>
                  <Text
                    size={18}
                    line={32}
                    bottom={32}
                    weight="400"
                    opacity={0.8}
                  >
                    Ana sayfa
                  </Text>
                </Col>
                <Col>
                  <Text
                    size={18}
                    line={32}
                    bottom={32}
                    weight="400"
                    opacity={0.8}
                  >
                    Blog
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} style={{ marginBottom: 24 }}>
              <Text size={18} line={32} bottom={32} weight="600">
                DİĞER
              </Text>
              <Row>
                <Col>
                  <Link to="/static/gizlilik-politikasi">
                    <Text size={18} line={32} weight="400" opacity={0.8}>
                      Gizlilik Politikası
                    </Text>
                  </Link>
                </Col>
                <Col>
                  <Link to="/static/kullanim-kosullari">
                    <Text size={18} line={32} weight="400" opacity={0.8}>
                      Kullanım Koşulları
                    </Text>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Component;
